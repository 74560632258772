import React from 'react'
import Layout from '../components/layouts'

const XlShadow =
  '    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25); box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),  var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);'

export default () => (
  <Layout>
    <div className="container">
      <div
        className="logo"
        style={{
          margin: '100px auto',
          width: '400px',
          height: '400px',
          borderRadius: '20000px',
          borderWidth: '50px',
        }}
      />
      <div style={{ display: 'flex' }}>
        <div
          className="logo"
          style={{
            padding: '40px',
            margin: '100px auto',
            fontSize: '200px',
            borderWidth: '35px',
            borderRadius: '125px',
          }}
        >
          <span
            style={{
              margin: '25px 0',
              fontSize: '75%',
            }}
          >
            T
          </span>
          H
          <span
            style={{
              margin: '25px 0',
              fontSize: '75%',
            }}
          >
            L
          </span>
        </div>
      </div>
    </div>
  </Layout>
)
